import * as React from "react"
import {Page} from "../components/page";
import Accordion from "../components/accordion";
import {accordionData} from "../content/content";
import './../bootstrap.scss';
import './../site.scss';
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const optionsGallery = {
    settings: {
        overlayColor: "rgb(25, 136, 124)",
        autoplaySpeed: 3000,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "#1b5245",
        iconColor: "rgba(126, 172, 139, 0.8)",
        showDownloadButton: false,
    },
    caption: {
        captionColor: "#a6cfa5",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    },
    thumbnails: {
/*        showThumbnails: false,*/
        thumbnailsAlignment: 'center',
        thumbnailsContainerBackgroundColor: 'transparent',
        thumbnailsContainerPadding: '40',
        thumbnailsGap: '10px 10px',
        thumbnailsIconColor: '#ffffff',
        thumbnailsOpacity: 0.6,
        thumbnailsPosition: 'bottom',
        thumbnailsSize: ['120px', '90px']
    },
};

/*const About = ({data}) => {*/
export default function About({data}) {

return (
      <Page>
        <p>ABout us page</p>

          <Container>
              <SimpleReactLightbox>
                  <SRLWrapper options={optionsGallery}>
                      <Row>
                          {data.gallery.edges.map(({node}) => (
                              <Col lg={2} md={6} key={node.id} className="py-3">
                                  {/* {node.base.split('-').join(' ').split('.')[0]} */}
                                  <a href={node.publicURL}>
                                      <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('-').join(' ').split('.')[0]} />
                                  </a>
                                  test
                              </Col>
                          ))}
                      </Row>
                  </SRLWrapper>
              </SimpleReactLightbox>
          </Container>


          <h1>React Accordion Demo</h1>
          <div className="accordion">
              {accordionData.map(({ title, content }) => (
                  <Accordion title={title} content={content} />
              ))}
          </div>



      </Page>
  )
}


export const pageQuery = graphql`
    query {
        gallery: allFile(filter: {
            relativeDirectory: {eq: "puerto-rico"}}
            sort: {fields: base, order: ASC}) {
            edges {
                node {
                    id
                    base
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            height: 600
                            width: 900
                            transformOptions: {fit: COVER}
                            placeholder: BLURRED
                            webpOptions: {quality: 50}
                        )
                    }
                }
            }
        }
    }
`